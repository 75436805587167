import { db } from "../services/Firebase";
import { clientAlgolia, indexNameAlgoliaProducts } from "../services/algolia";
// import { clientAlgolia } from "../services/algolia";
import { strAllCategoriesProducts } from "./categories";

export const Product = {
  pageProductsLimit: 50,
  productsFirstBatch: async function (
    storeNAME,
    selectCategorie,
    filterSelect,
    filters
  ) {
    try {
      const docReference = db.collection("stores");

      let query = docReference
        .doc(storeNAME)
        .collection("products")
        .orderBy(
          filters[filterSelect - 1]?.fieldFirestoreQueryOrder?.fieldPath,
          filters[filterSelect - 1]?.fieldFirestoreQueryOrder?.directionStr
        );

      let products = [];
      let lastKey = "";

      if (selectCategorie[0] === strAllCategoriesProducts) {
        query = query.limit(Product.pageProductsLimit);

        const data = await query.get();

        data.forEach((doc) => {
          products.push({ id: doc.id, ...doc.data() });

          lastKey = doc;
        });

        return { products, lastKey };
      } else {
        query = query.limit(Product.pageProductsLimit);

        const { products, lastKey } =
          await Product?.listProductByCategorySingle(selectCategorie, query);

        return { products, lastKey };
      }
    } catch (e) {
      console.log(e);
    }
  },
  productsNextBatch: async function (
    storeNAME,
    key,
    filterSelect,
    filters,
    selectCategorie
  ) {
    try {
      const docReference = db.collection("stores");

      let query = docReference
        .doc(storeNAME)
        .collection("products")
        .orderBy(
          filters[filterSelect - 1]?.fieldFirestoreQueryOrder?.fieldPath,
          filters[filterSelect - 1]?.fieldFirestoreQueryOrder?.directionStr
        )
        .startAfter(key)
        .limit(Product.pageProductsLimit);
      let products = [];
      let lastKey = "";

      if (selectCategorie[0] === strAllCategoriesProducts) {
        const data = await query.get();

        data.forEach((doc) => {
          products.push({ id: doc.id, ...doc.data() });
          lastKey = doc;
        });

        return { products, lastKey };
      } else {
        query = query.limit(Product.pageProductsLimit);

        const { products, lastKey } =
          await Product?.listProductByCategorySingle(selectCategorie, query);

        return { products, lastKey };
      }
    } catch (e) {
      console.log(e);
    }
  },
  listProductByCategorySingle: async (category, orderByQuery) => {
    const map = {
      id: category?.id,
      name: category?.name,
    };

    const query1 = orderByQuery?.where("categories", "array-contains", map);
    const list1 = await queryAsync(query1);

    const query2 = orderByQuery?.where("categories", "array-contains", {
      id: null,
      name: category?.name,
    });
    const list2 = await queryAsync(query2);

    const query3 = orderByQuery?.where("categories", "array-contains", {
      name: category?.name,
    });
    const list3 = await queryAsync(query3);

    const completeList = [
      ...list1.products,
      ...list2.products,
      ...list3.products,
    ];
    
    return { products: completeList, lastKey: list3.lastKey };
  },
  searchProducts: async (search, storeName) => {
    const { results } = await clientAlgolia.search({
      requests: [
        {
          indexName: indexNameAlgoliaProducts,
          query: search,
          hitsPerPage: Product.pageProductsLimit,
          filters: `storeName:${storeName}`,
        },
      ],
    });

    return results;
  },
};

export const queryAsync = async (query) => {
  const products = [];
  let lastKey = "";

  try {
    const data = await query?.get();

    data.forEach((doc) => {
      products.push({ id: doc.id, ...doc.data() });

      lastKey = doc;
    });
    return { products, lastKey };
  } catch (error) {
    console.error("Error: ", error);
    return [];
  }
};
