import React from "react";
import { useStateValue } from "../../providers/StateProvider";
import { changeLanguage } from "../../providers/strings";

import "../../styles/CatalogoDigital/Catalogo.css";

function Footer({ zindex0Footer }) {
  const [{ activeLaguage, activeLaguageStrings }] = useStateValue();

  return (
    <div
      style={{ zIndex: zindex0Footer ? -1 : 0 }}
      className="myne-catalogo--footer"
    >
      <p>
        {changeLanguage(
          activeLaguageStrings?.footer?.title,
          "Crie um catálogo online como esse com o",
          activeLaguage
        )}{" "}
        <strong onClick={() => window.open("https://myneapp.com.br/")}>
          App Myne.
        </strong>
      </p>
    </div>
  );
}

export default Footer;
