// CHANGE LANGUAGE

export const changeLanguage = (languagesString, ptString, activeLaguage) => {
  return activeLaguage === "pt" || activeLaguage == null
    ? ptString
    : languagesString;
};

// PT is default

// EN

export const stringsEN = {
  messages: {
    error: "Something went wrong. Try later.",
    addedProducts: "Products added to cart!",
    addedProduct: "Product added to cart!",
  },
  buttonOrder: "ORDER USING WHATSAPP",
  buttonAddItem: "ADD ITEM",
  header: {
    buttonToCart: "Shopping Cart",
    buttonToCartResponsive: "Cart",
  },
  filtersSidebar: {
    filter1: {
      title: "Order By",
      f1: "Lowest price",
      f2: "Biggest price",
      f3: "A-Z",
      f4: "Z-A",
    },
    filter2: {
      title: "Layout",
      f1: "Card",
      f2: "List",
    },
  },
  dica: "Tip: Do not make payments to strangers. The offers and products presented in this catalog are the responsibility of",
  search: "Search for product",
  notResultsSearch: "Results not found. Try again searching for other terms!",
  buttonProduct: "BUY",
  notDetailsProduct: "No details about the product were provided.",
  footer: {
    title: "Build a online catalog like this using",
  },
  cart: {
    notItensCart: "The shopping cart is empty!",
    links: {
      l1: "Home",
      l2: "Shopping Cart",
    },
    removeCartButton: "Empty Cart",
    removeCartButtonResponsive: "Empty",
    cartItens: {
      i1: "Items",
      i2: "Price",
      i3: "Amount",
      i4: "Total",
    },
    titleTotalPrice: "Total Price:",
    titleTotalPriceResponsive: "Total:",
    buttonContinueBuy: "KEEPING BUYING",
    buttonBack: "Back",
    buttonOrder: "ORDER USING WHATSAPP",
  },
};

// ES

export const stringsES = {
  messages: {
    error: "Algo salió mal. Intenta más tarde.",
    addedProducts: "Productos añadidos a la cesta!",
    addedProduct: "Producto añadido a la cesta!",
  },
  buttonOrder: "PEDIR POR WHATSAPP",
  buttonAddItem: "Añadir  artículo",
  header: {
    buttonToCart: "Mi cesta",
    buttonToCartResponsive: "Mi cesta",
  },
  filtersSidebar: {
    filter1: {
      title: "Ordenar por",
      f1: "Menor precio",
      f2: "Mayor precio",
      f3: "A-Z",
      f4: "Z-A",
    },
    filter2: {
      title: "Visualización",
      f1: "Tarjeta",
      f2: "Lista",
    },
  },
  dica: "Consejo: no haga pagos a desconocidos. Las ofertas y productos presentados en ese catálogo son de responsabilidad de",
  search: "Buscar producto",
  notResultsSearch:
    "Resultados no encontrados. ¡Intente nuevamente buscando otros términos!",
  buttonProduct: "COMPRAR",
  notDetailsProduct: "No se proporcionaron detalles sobre el producto.",
  footer: {
    title: "Haga un catálogo online como ese con",
  },
  cart: {
    notItensCart: "Su cesta está vacía!",
    links: {
      l1: "Inicio",
      l2: "Cesta",
    },
    removeCartButton: "Vaciar su cesta",
    removeCartButtonResponsive: "Vaciar",
    cartItens: {
      i1: "Artículos",
      i2: "Valor unit.",
      i3: "Cantidad",
      i4: "Valor total",
    },
    titleTotalPrice: "Valor total:",
    titleTotalPriceResponsive: "Total:",
    buttonContinueBuy: "SEGUIR COMPRANDO",
    buttonBack: "VOLVER",
    buttonOrder: "PEDIR POR WHATSAPP",
  },
};
