import React, { useState } from "react";

const InputForm = ({
  marginTop,
  notLabel,
  onSubmit,
  icon,
  search,
  senha,
  value,
  id,
  resetValue,
  label,
  responsive,
  ...rest
}) => {
  const [senhaView, setSenhaView] = useState(false);

  var numero = Math.random() * 10;

  return (
    <form
      style={{ marginTop: marginTop }}
      onSubmit={(event) => event.preventDefault()}
      className="input-form"
    >
      {search ? (
        value !== "" || responsive ? (
          <IconRemove onClick={resetValue} />
        ) : icon ? (
          icon
        ) : (
          ""
        )
      ) : (
        ""
      )}

      {senha ? (
        senhaView ? (
          <svg
            onClick={() => setSenhaView(false)}
            width="24"
            height="16"
            viewBox="0 0 24 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.6"
              d="M5.29688 3.09375C7.29688 1.6875 9.53125 0.984375 12 0.984375C14.4688 0.984375 16.7031 1.6875 18.7031 3.09375C20.7031 4.46875 22.1406 6.26562 23.0156 8.48438C22.1406 10.7344 20.7031 12.5469 18.7031 13.9219C16.7031 15.2969 14.4688 15.9844 12 15.9844C9.53125 15.9844 7.29688 15.2969 5.29688 13.9219C3.29688 12.5469 1.85938 10.7344 0.984375 8.48438C1.85938 6.26562 3.29688 4.46875 5.29688 3.09375ZM8.4375 12.0469C9.4375 13.0156 10.625 13.5 12 13.5C13.375 13.5 14.5469 13.0156 15.5156 12.0469C16.5156 11.0469 17.0156 9.85938 17.0156 8.48438C17.0156 7.10938 16.5156 5.9375 15.5156 4.96875C14.5469 4 13.375 3.51562 12 3.51562C10.625 3.51562 9.4375 4 8.4375 4.96875C7.46875 5.9375 6.98438 7.10938 6.98438 8.48438C6.98438 9.85938 7.46875 11.0469 8.4375 12.0469ZM9.89062 6.375C10.4844 5.78125 11.1875 5.48438 12 5.48438C12.8125 5.48438 13.5156 5.78125 14.1094 6.375C14.7031 6.96875 15 7.67188 15 8.48438C15 9.32812 14.7031 10.0469 14.1094 10.6406C13.5156 11.2031 12.8125 11.4844 12 11.4844C11.1875 11.4844 10.4844 11.2031 9.89062 10.6406C9.29688 10.0469 9 9.32812 9 8.48438C9 7.67188 9.29688 6.96875 9.89062 6.375Z"
              fill="black"
            />
          </svg>
        ) : (
          <svg
            onClick={() => setSenhaView(true)}
            width="24"
            height="20"
            viewBox="0 0 24 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.6"
              d="M12 4.51562C11.5625 4.51562 11.0781 4.59375 10.5469 4.75L8.34375 2.54688C9.6875 2.17188 10.9062 1.98438 12 1.98438C14.4688 1.98438 16.7031 2.6875 18.7031 4.09375C20.7031 5.46875 22.1406 7.26562 23.0156 9.48438C22.3281 11.2344 21.2656 12.75 19.8281 14.0312L16.7812 10.9375C16.9375 10.4688 17.0156 9.98438 17.0156 9.48438C17.0156 8.10938 16.5156 6.9375 15.5156 5.96875C14.5469 5 13.375 4.51562 12 4.51562ZM2.71875 1.14062C2.90625 0.953125 3.14062 0.859375 3.42188 0.859375C3.70312 0.859375 3.9375 0.953125 4.125 1.14062L20.4375 17.5C20.625 17.6875 20.7188 17.9219 20.7188 18.2031C20.7188 18.4844 20.625 18.7188 20.4375 18.9062C20.25 19.0938 20.0156 19.1875 19.7344 19.1875C19.4531 19.1875 19.2188 19.0938 19.0312 18.9062L16.3125 16.1875C14.9062 16.7188 13.4688 16.9844 12 16.9844C9.53125 16.9844 7.29688 16.2969 5.29688 14.9219C3.29688 13.5469 1.85938 11.7344 0.984375 9.48438C1.79688 7.48438 3.03125 5.84375 4.6875 4.5625L2.71875 2.54688C2.21875 2.10938 2.21875 1.64063 2.71875 1.14062ZM12 14.5C12.75 14.5 13.4688 14.3281 14.1562 13.9844L12.5625 12.4375C12.4375 12.4688 12.25 12.4844 12 12.4844C11.1875 12.4844 10.4844 12.2031 9.89062 11.6406C9.29688 11.0469 9 10.3281 9 9.48438C9 9.42188 9 9.32812 9 9.20312C9.03125 9.07812 9.04688 8.98438 9.04688 8.92188L7.5 7.375C7.15625 8 6.98438 8.70312 6.98438 9.48438C6.98438 10.8594 7.46875 12.0469 8.4375 13.0469C9.4375 14.0156 10.625 14.5 12 14.5ZM14.9531 9.15625L12.3281 6.53125C13.0469 6.59375 13.6406 6.875 14.1094 7.375C14.6094 7.875 14.8906 8.46875 14.9531 9.15625Z"
              fill="black"
            />
          </svg>
        )
      ) : (
        ""
      )}
      {senha ? (
        <div
          style={{ padding: notLabel ? 0 : "" }}
          className="form__group field"
        >
          <input
            placeholder={label}
            autoComplete="new-password"
            type={senhaView ? "text" : "password"}
            {...rest}
            value={value}
            className="form__field"
            required
            id={id ? id : label + numero}
          />
          {!notLabel ? (
            <label
              htmlFor={id ? id : label + numero}
              className="form__label-senha"
            >
              {label}
            </label>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div
          style={{ padding: notLabel ? 0 : "" }}
          className="form__group field"
        >
          <input
            placeholder={label}
            autoComplete="new-password"
            {...rest}
            value={value}
            className="form__field"
            required
            id={id ? id : label + numero}
          />
          {!notLabel ? (
            <label htmlFor={id ? id : label + numero} className="form__label">
              {label}
            </label>
          ) : (
            ""
          )}
        </div>
      )}
    </form>
  );
};

export default InputForm;

const IconRemove = ({ ...rest }) => (
  <svg
    {...rest}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.015625C4.46875 0.015625 0.015625 4.46875 0.015625 10C0.015625 15.5312 4.46875 19.9844 10 19.9844C15.5312 19.9844 19.9844 15.5312 19.9844 10C19.9844 4.46875 15.5312 0.015625 10 0.015625ZM14.3125 14.3125C13.8906 14.6875 13.2812 14.6875 12.9062 14.3125L10 11.4062L7.09375 14.3125C6.71875 14.6875 6.10938 14.6875 5.6875 14.3125C5.3125 13.8906 5.3125 13.2812 5.6875 12.9062L8.59375 10L5.6875 7.09375C5.3125 6.71875 5.3125 6.10938 5.6875 5.6875C6.10938 5.3125 6.71875 5.3125 7.09375 5.6875L10 8.59375L12.9062 5.6875C13.2812 5.3125 13.8906 5.3125 14.3125 5.6875C14.6875 6.10938 14.6875 6.71875 14.3125 7.09375L11.4062 10L14.3125 12.9062C14.6875 13.2812 14.6875 13.8906 14.3125 14.3125Z"
      fill="black"
      fill-opacity="0.6"
    />
  </svg>
);
