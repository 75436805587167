import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import InputForm from "../../components/inputs/InputForm";
import {
  useOnClickOutside,
  useWindowDimensions,
} from "../../providers/reducer";
import { useStateValue } from "../../providers/StateProvider";
import { changeLanguage } from "../../providers/strings";

import "../../styles/CatalogoDigital/Catalogo.css";
import "../../styles/Header.css";

function Header({
  subdomain,
  cart,
  carrinho,
  setCarrinho,
  filters,
  filterSelect,
  setFilterSelect,
  searchProduct,
  setSearchProduct,
  categories,
  selectCategories,
  setSelectCategories,
  existCategorie,
  removeCategorie,
}) {
  const history = useHistory();
  const { store } = useParams();
  const [{ store_data, activeLaguageStrings, activeLaguage }, dispatch] =
    useStateValue();
  const storeNAME = subdomain ? subdomain : store;
  const [searchOpen, setSearchOpen] = useState(false);

  const searchOpenRef = useRef();
  useOnClickOutside(searchOpenRef, () => setSearchOpen(false));

  const { width } = useWindowDimensions();

  return (
    <div
      className={
        window.location.pathname !== "/" && width <= 1340
          ? "myne-catalogo-digital--header myne-catalogo-digital--header_responsive_cart"
          : "myne-catalogo-digital--header"
      }
    >
      {/* STORE NAME */}
      <p>{store_data?.storeName}</p>
      {!cart ? (
        <button
          onClick={() => {
            if (subdomain) {
              history.push(`/carrinho`);
            } else {
              history.push(`/${storeNAME}/carrinho`);
            }
            dispatch({
              type: "SET_MESSAGES",
              messages: {
                messageProductAdd: false,
                messageProductsAdd: false,
                messageErro: false,
              },
            });
          }}
        >
          <IconCart />
          {carrinho?.length > 0 ? <span></span> : ""}

          {changeLanguage(
            activeLaguageStrings?.header?.buttonToCart,
            "Meu carrinho",
            activeLaguage
          )}
        </button>
      ) : (
        ""
      )}

      {/* RESPONSIVE */}
      {cart && window.location.pathname !== "/" && width <= 1340 ? (
        <div className="myne-catalogo-digital--header__responsive-cart">
          <section>
            <IconClose onClick={() => history.push(`/`)} />
            <p>
              {changeLanguage(
                activeLaguageStrings?.header?.buttonToCartResponsive,
                "Carrinho",
                activeLaguage
              )}
            </p>
          </section>
          <p>{store_data?.storeName}</p>
        </div>
      ) : !cart ? (
        <>
          <div className="myne-catalogo-digital--header__responsive-texts">
            <div
              style={{
                opacity: searchOpen ? 1 : 0,
                visibility: searchOpen ? "visible" : "hidden",
                position: !searchOpen ? "absolute" : "",
                left: !searchOpen ? "-100%" : "",
              }}
              ref={searchOpenRef}
              className="myne-catalogo-content--search myne-catalogo-content--search-responsive"
            >
              <InputForm
                icon={<IconSearch />}
                label="Buscar produto"
                search
                value={searchProduct}
                resetValue={() => {
                  setSearchOpen(false);
                  setSearchProduct("");
                }}
                id={"input-search-responsive-product"}
                responsive
                autofocus
                type="text"
                onChange={(e) => setSearchProduct(e.target.value)}
              />
            </div>
            <section
              style={{
                opacity: !searchOpen ? 1 : 0,
                visibility: !searchOpen ? "visible" : "hidden",
                position: searchOpen ? "absolute" : "",
                right: searchOpen ? "-100%" : "",
              }}
            >
              <p>{store_data?.storeName}</p>
              <button
                onClick={() => {
                  setSearchOpen(true);
                  document
                    .getElementById("input-search-responsive-product")
                    .focus();
                }}
              >
                <IconSearch />
              </button>
            </section>
            <button
              style={{ marginLeft: 16 }}
              onClick={() => {
                if (subdomain) {
                  history.push(`/carrinho`);
                } else {
                  history.push(`/${storeNAME}/carrinho`);
                }
                dispatch({
                  type: "SET_MESSAGES",
                  messages: {
                    messageProductAdd: false,
                    messageProductsAdd: false,
                    messageErro: false,
                  },
                });
              }}
            >
              <IconCart />
              {carrinho?.length > 0 ? <span></span> : ""}
            </button>
          </div>

          <div className="myne-catalogo-digital--header__responsive-filters">
            {filters?.map((item) => (
              <button
                style={{
                  marginRight: item.index === filters.length ? "0" : "",
                }}
                className={
                  filterSelect === item.index
                    ? "myne-catalogo-digital--header__responsive-filters-btn myne-catalogo-digital--header__responsive-filters-btn-check"
                    : "myne-catalogo-digital--header__responsive-filters-btn"
                }
                key={item.id}
                onClick={() => setFilterSelect(item.index)}
              >
                <p>{item.name}</p>
              </button>
            ))}
          </div>

          {/* CATEGORY */}
          {/* {categories?.length > 0 ? ( */}
          <div className="myne-catalogo-header--filter">
            <button
              className={
                selectCategories[0] === "AllCategories-Myne-Catalog"
                  ? "myne-catalogo-header--filter-btn myne-catalogo-header--filter-btn-active"
                  : "myne-catalogo-header--filter-btn"
              }
              onClick={() => {
                setSelectCategories(["AllCategories-Myne-Catalog"]);
              }}
            >
              Todos
            </button>

            {categories?.map((item, i) => (
              <button
                className={
                  existCategorie(item)
                    ? "myne-catalogo-header--filter-btn myne-catalogo-header--filter-btn-active"
                    : "myne-catalogo-header--filter-btn"
                }
                onClick={() => {
                  setSelectCategories(item);
                }}
              >
                {item?.name}
              </button>
            ))}
          </div>
          {/* ) : (
            ""
          )} */}
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default Header;

const IconClose = ({ ...rest }) => (
  <svg
    {...rest}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.2812 0.71875C12.9062 0.296875 12.2969 0.296875 11.875 0.71875L7 5.59375L2.125 0.71875C1.70312 0.296875 1.09375 0.296875 0.71875 0.71875C0.296875 1.09375 0.296875 1.70312 0.71875 2.125L5.59375 7L0.71875 11.875C0.296875 12.2969 0.296875 12.9062 0.71875 13.2812C1.09375 13.7031 1.70312 13.7031 2.125 13.2812L7 8.40625L11.875 13.2812C12.2969 13.7031 12.9062 13.7031 13.2812 13.2812C13.7031 12.9062 13.7031 12.2969 13.2812 11.875L8.40625 7L13.2812 2.125C13.7031 1.75 13.7031 1.09375 13.2812 0.71875Z"
      fill="black"
      fill-opacity="0.87"
    />
  </svg>
);

const IconSearch = ({ ...rest }) => (
  <svg
    {...rest}
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5156 12.0156H12.7188L12.4375 11.7344C13.6094 10.3281 14.2656 8.40625 13.8906 6.39062C13.4219 3.625 11.125 1.375 8.3125 1.04688C4.09375 0.53125 0.53125 4.09375 1.04688 8.3125C1.375 11.125 3.625 13.4219 6.39062 13.8906C8.40625 14.2656 10.3281 13.6094 11.7344 12.4375L12.0156 12.7188V13.5156L16.2344 17.7344C16.6562 18.1562 17.3125 18.1562 17.7344 17.7344C18.1562 17.3594 18.1562 16.6562 17.7344 16.2812L13.5156 12.0156ZM7.51562 12.0156C5.03125 12.0156 3.01562 10 3.01562 7.51562C3.01562 5.03125 5.03125 3.01562 7.51562 3.01562C10 3.01562 12.0156 5.03125 12.0156 7.51562C12.0156 10 10 12.0156 7.51562 12.0156Z"
      fill="black"
      fill-opacity="0.87"
    />
  </svg>
);

const IconCart = () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.98438 16C5.90625 16 5.01562 16.8906 5.01562 18.0156C5.01562 19.0938 5.90625 19.9844 6.98438 19.9844C8.10938 19.9844 9 19.0938 9 18.0156C9 16.8906 8.10938 16 6.98438 16ZM0.984375 1C0.984375 1.5625 1.45312 1.98438 2.01562 1.98438H3L6.60938 9.57812L5.25 12.0156C4.5 13.375 5.48438 15.0156 6.98438 15.0156H18C18.5625 15.0156 18.9844 14.5469 18.9844 13.9844C18.9844 13.4688 18.5625 13 18 13H6.98438L8.10938 10.9844H15.5625C16.3125 10.9844 16.9688 10.6094 17.2969 9.95312L20.8594 3.48438C21.2344 2.82812 20.7656 1.98438 20.0156 1.98438H5.20312L4.54688 0.578125C4.35938 0.203125 4.03125 0.015625 3.65625 0.015625H2.01562C1.45312 0.015625 0.984375 0.4375 0.984375 1ZM17.0156 16C15.8906 16 15 16.8906 15 18.0156C15 19.0938 15.8906 19.9844 17.0156 19.9844C18.0938 19.9844 18.9844 19.0938 18.9844 18.0156C18.9844 16.8906 18.0938 16 17.0156 16Z"
      fill="black"
      fill-opacity="0.87"
    />
  </svg>
);
