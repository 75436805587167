import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA5mRwBcge1preOmtXV_kZrDZ96rGIlQns",
  authDomain: "meunegocio-9790f.firebaseapp.com",
  databaseURL: "https://meunegocio-9790f.firebaseio.com",
  projectId: "meunegocio-9790f",
  storageBucket: "meunegocio-9790f.appspot.com",
  messagingSenderId: "869037709483",
  appId: "1:869037709483:web:af0758c9d40305c2267fee",
  measurementId: "G-E6TG4R259T",
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const analytics = firebase.analytics();

export { analytics, db, firebase };
