import { useEffect, useState } from "react";

export const initialState = {
  messages: {},
  store_data: {},
  activeMoeda: {},
  activeLaguage: "pt",
  activeLaguageStrings: {},
};

export const actionTypes = {
  SET_MESSAGES: "SET_MESSAGES",
  SET_STORE_DATA: "SET_STORE_DATA",
  SET_ACTIVE_MOEDA: "SET_ACTIVE_MOEDA",
  SET_ACTIVE_LANGUAGE: "SET_ACTIVE_LANGUAGE",
  SET_ACTIVE_LANGUAGE_STRINGS: "SET_ACTIVE_LANGUAGE_STRINGS",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_LANGUAGE_STRINGS:
      return {
        ...state,
        activeLaguageStrings: action.activeLaguageStrings,
      };
    case actionTypes.SET_MESSAGES:
      return {
        ...state,
        messages: action.messages,
      };
    case actionTypes.SET_ACTIVE_MOEDA:
      return {
        ...state,
        activeMoeda: action.activeMoeda,
      };
    case actionTypes.SET_ACTIVE_LANGUAGE:
      return {
        ...state,
        activeLaguage: action.activeLaguage,
      };
    case actionTypes.SET_STORE_DATA:
      return {
        ...state,
        store_data: action.store_data,
      };
    default:
      return state;
  }
};

export default reducer;

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref?.current || ref?.current?.contains(event?.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

export function useWindowDimensions() {
  const hasWindow = typeof window !== "undefined";

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
}

export const removeAcento = (text) => {
  text = text?.toString()?.toLowerCase();
  text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
  text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
  text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
  text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
  text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
  text = text.replace(new RegExp("[Ç]", "gi"), "c");
  return text;
};

export function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

export function getOffset(el) {
  const rect = el?.getBoundingClientRect();
  return {
    left: rect?.left + window?.scrollX,
    top: rect?.top + window?.scrollY,
  };
}

// GET MOEDA

export const FilterMoeda = (moedas, store) => {
  const filter = moedas?.filter((item) => {
    return item?.currencyId === store?.currencyId;
  });
  return filter[0];
};
