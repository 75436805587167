import React from "react";
import { ButtonTopScroll } from "./styles";

function Btntopscrool({ top, onClick }) {
  return (
    <ButtonTopScroll
      style={{ bottom: top ? "69px" : "30px", opacity: top ? "1" : "0" }}
      onClick={onClick}
    >
      <IconArrowTop />
    </ButtonTopScroll>
  );
}

export default Btntopscrool;

const IconArrowTop = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_7215_27264"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_7215_27264)">
      <path
        d="M11 8.80039L8.10005 11.7004C7.91672 11.8837 7.68338 11.9754 7.40005 11.9754C7.11672 11.9754 6.88338 11.8837 6.70005 11.7004C6.51672 11.5171 6.42505 11.2837 6.42505 11.0004C6.42505 10.7171 6.51672 10.4837 6.70005 10.3004L11.3 5.70039C11.5 5.50039 11.7334 5.40039 12 5.40039C12.2667 5.40039 12.5 5.50039 12.7 5.70039L17.3 10.3004C17.4834 10.4837 17.575 10.7171 17.575 11.0004C17.575 11.2837 17.4834 11.5171 17.3 11.7004C17.1167 11.8837 16.8834 11.9754 16.6 11.9754C16.3167 11.9754 16.0834 11.8837 15.9 11.7004L13 8.80039V17.0004C13 17.2837 12.9042 17.5212 12.7125 17.7129C12.5209 17.9046 12.2834 18.0004 12 18.0004C11.7167 18.0004 11.4792 17.9046 11.2875 17.7129C11.0959 17.5212 11 17.2837 11 17.0004V8.80039Z"
        fill="black"
        fill-opacity="0.87"
      />
    </g>
  </svg>
);
