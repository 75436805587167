import { algoliasearch } from "algoliasearch";

export const clientAlgolia = algoliasearch(
  process.env.REACT_APP_VERCEL_ENV_ALGOLIA_ID,
  process.env.REACT_APP_VERCEL_ENV_ALGOLIA_KEY
);

export const indexNameAlgoliaProducts = "prod_PRODUCTS_BY_USER";

// clientAlgolia.setSettings({
//   indexName: indexNameAlgoliaProducts,
//   indexSettings: {
//     attributesForFaceting: ["userId", 'storeName'],
//     searchableAttributes: ["name", "description"],
//   },
// });
