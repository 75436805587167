import { changeLanguage } from "../providers/strings";

export const getFilters = (activeLaguageStrings, activeLaguage) => [
  {
    index: 1,
    name: changeLanguage(
      activeLaguageStrings?.filtersSidebar?.filter1?.f1,
      "Menor preço",
      activeLaguage
    ),
    id: "menor-preco",
    fieldFirestoreQueryOrder: {
      fieldPath: "salePrice",
      directionStr: "asc",
    },
  },
  {
    index: 2,
    name: changeLanguage(
      activeLaguageStrings?.filtersSidebar?.filter1?.f2,
      "Maior preço",
      activeLaguage
    ),
    id: "maior-preco",
    fieldFirestoreQueryOrder: {
      fieldPath: "salePrice",
      directionStr: "desc",
    },
  },
  {
    index: 3,
    name: changeLanguage(
      activeLaguageStrings?.filtersSidebar?.filter1?.f3,
      "A-Z",
      activeLaguage
    ),
    id: "A-Z",
    fieldFirestoreQueryOrder: {
      fieldPath: "name",
      directionStr: "asc",
    },
  },
  {
    index: 4,
    name: changeLanguage(
      activeLaguageStrings?.filtersSidebar?.filter1?.f4,
      "Z-A",
      activeLaguage
    ),
    id: "Z-A",
    fieldFirestoreQueryOrder: {
      fieldPath: "name",
      directionStr: "desc",
    },
  },
];
export const getExhibitions = (activeLaguageStrings, activeLaguage) => [
  {
    index: 1,
    name: changeLanguage(
      activeLaguageStrings?.filtersSidebar?.filter2?.f1,
      "Cartão",
      activeLaguage
    ),
    id: "cart",
  },
  {
    index: 2,
    name: changeLanguage(
      activeLaguageStrings?.filtersSidebar?.filter2?.f2,
      "Lista",
      activeLaguage
    ),
    id: "list",
  },
];
