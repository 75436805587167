// CART FUNCTIONS

import { analyticsEvent } from "./analytics";

export const onUpdateCART = (item, carrinho, product, setCarrinho) => {
  const cart = carrinho.map((c) => (c?.id === product?.id ? item : c));

  setCarrinho(cart);
};
export const removeItemCART = (id, carrinho, setCarrinho) => {
  var cartItems = [...carrinho];
  const index = cartItems.findIndex((x) => x.id === id);

  if (index >= 0) {
    cartItems.splice(index, 1);
    setCarrinho(cartItems);
  }
};

export const checkAddedCART = (item, carrinho) => {
  const exist = carrinho.filter((x) => {
    var filter = x?.id === item?.id;

    return filter;
  });

  return exist?.length !== 0;
};
export const addItemCART = (
  modaladdproduct,
  carrinho,
  amountproduct,
  setCarrinho,
  setModaladdproduct,
  history,
  dispatch
) => {
  // CHECK ADD CART
  if (checkAddedCART(modaladdproduct, carrinho)) {
    // UPDATE
    const array = carrinho.map((cart) =>
      cart?.id === modaladdproduct?.id
        ? {
            ...modaladdproduct,
            amount: cart?.amount + amountproduct,
          }
        : cart
    );
    setCarrinho(array);

    history.push(`/carrinho/?id=${modaladdproduct.id}`);

    setModaladdproduct(null);
    analyticsEvent("PRODUCT_AMOUNT_CHANGED");
  } else {
    // ADD
    carrinho.push({
      ...modaladdproduct,
      amount: amountproduct,
    });
    if (amountproduct > 1) {
      dispatch({
        type: "SET_MESSAGES",
        messages: {
          messageProductsAdd: true,
        },
      });
    } else {
      dispatch({
        type: "SET_MESSAGES",
        messages: {
          messageProductAdd: true,
        },
      });
    }

    setModaladdproduct(null);
    analyticsEvent("PRODUCT_AMOUNT_CHANGED");
  }
};

// FORMAT MESSAGE WHATSAPP

export const pularLinha = "%0A";

export const pedirWhatsapp = (number, text) => {
  analyticsEvent("BUY_USING_WHATSAPP");
  window.open(`https://api.whatsapp.com/send?phone=${number}&text=${text}`);
};

export const formatTextMessage = (
  products,
  total,
  activeMoeda,
  activeLaguage
) => {
  const messagePT = `Olá, Gostaria de pedir:${pularLinha}${pularLinha}Qtd                    Descrição${pularLinha}${`${products?.map(
    (product, i) =>
      `${product?.amount}X                      ${product?.name}${
        products?.length === 1 || products?.length === i + 1
          ? ``
          : `${pularLinha}`
      }`
  )}`?.replaceAll(
    ",",
    ""
  )}${pularLinha}${pularLinha}💰 TOTAL A PAGAR: ${total?.toLocaleString(
    "pt-br",
    {
      style: "currency",
      currency: activeMoeda?.currencyId ? activeMoeda?.currencyId : "BRL",
    }
  )}`;

  const messageEN = `Hello, I would like to order:${pularLinha}${pularLinha}Qty                    Description${pularLinha}${`${products?.map(
    (product, i) =>
      `${product?.amount}X                      ${product?.name}${
        products?.length === 1 || products?.length === i + 1
          ? ``
          : `${pularLinha}`
      }`
  )}`?.replaceAll(
    ",",
    ""
  )}${pularLinha}${pularLinha}💰 TOTAL PRICE: ${total?.toLocaleString("pt-br", {
    style: "currency",
    currency: activeMoeda?.currencyId ? activeMoeda?.currencyId : "BRL",
  })}`;

  const messageES = `Hola, me gustaría ordenar:${pularLinha}${pularLinha}Cantidad           Descripción${pularLinha}${`${products?.map(
    (product, i) =>
      `${product?.amount}X                      ${product?.name}${
        products?.length === 1 || products?.length === i + 1
          ? ``
          : `${pularLinha}`
      }`
  )}`?.replaceAll(
    ",",
    ""
  )}${pularLinha}${pularLinha}💰 TOTAL A PAGAR: ${total?.toLocaleString(
    "pt-br",
    {
      style: "currency",
      currency: activeMoeda?.currencyId ? activeMoeda?.currencyId : "BRL",
    }
  )}`;

  if (activeLaguage === "pt") {
    return messagePT;
  } else if (activeLaguage === "en") {
    return messageEN;
  } else if (activeLaguage === "es") {
    return messageES;
  } else {
    return messageEN;
  }
};
