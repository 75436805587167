import styled from "styled-components";

export const ButtonTopScroll = styled.button`
  position: fixed;
  bottom: 30px;
  opacity: 1;
  right: 243px;
  border-radius: 100%;
  border: none;
  outline: none;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
  background: var(--azul-myne);
  z-index: 999;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(1);
  }

  @media (max-width: 1340px) {
    right: 24px;
  }
`;
