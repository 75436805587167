import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { useHistory, useParams } from "react-router-dom";
import ItemCart from "../../components/Cards/ItemCart";
import { formatTextMessage, pedirWhatsapp } from "../../providers/catalogo";

import "../../styles/CatalogoDigital/Carrinho.css";
import { useStateValue } from "../../providers/StateProvider";
import ItemCartResponsive from "../../components/Cards/ItemCartResponsive";
import {
  getOffset,
  getQueryVariable,
  useWindowDimensions,
} from "../../providers/reducer";
import { changeLanguage } from "../../providers/strings";
import { analyticsEvent } from "../../providers/analytics";

function Carrinho({ subdomain, carrinho, setCarrinho }) {
  const { store } = useParams();
  const storeNAME = subdomain ? subdomain : store;
  const [{ activeMoeda, activeLaguage, activeLaguageStrings }] =
    useStateValue();

  const foo = getQueryVariable("id");

  useEffect(() => {
    setTimeout(() => {
      const el = document.getElementById(`id=${foo}=myneid`);
      if (el)
        document.getElementById("myne-catalogo-content").scrollTo({
          top: getOffset(el)?.top - 100,
          behavior: "smooth",
        });
    }, [400]);
  }, [foo]);

  const history = useHistory();
  const [total, setTotal] = useState(0);
  const [{ store_data }] = useStateValue();

  useEffect(() => {
    if (!store_data?.storeName) {
      history.push("/");
    }
  }, [store_data]);

  const notItens = carrinho?.length <= 0;

  useEffect(() => {
    var total = 0;
    carrinho.map((product) => {
      const totalproduct = product?.salePrice * product?.amount;
      total += totalproduct;
    });
    setTotal(total);
  }, [carrinho]);

  const { width } = useWindowDimensions();

  return (
    <div className="myne-catalogo">
      <Header cart carrinho={carrinho} setCarrinho={setCarrinho} />

      <div className="myne-carrinho">
        <div
          className="myne-catalogo-content-responsive-cart myne-catalogo-content"
          id="myne-catalogo-content"
        >
          <div className="myne-carrinho-content">
            {width > 1340 && (
              <div className="myne-carrinho-content__header">
                <section className="myne-carrinho-content__link">
                  <a
                    onClick={() =>
                      subdomain
                        ? history.push(`/`)
                        : history.push(`/${storeNAME}`)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {changeLanguage(
                      activeLaguageStrings?.cart?.links?.l1,
                      "Início",
                      activeLaguage
                    )}
                  </a>
                  <p>
                    {" "}
                    {iconRight}{" "}
                    {changeLanguage(
                      activeLaguageStrings?.cart?.links?.l2,
                      "Carrinho",
                      activeLaguage
                    )}
                  </p>
                </section>
                {!notItens && (
                  <button
                    onClick={() => {
                      setCarrinho([]);
                      analyticsEvent("EMPTY_CART_CLICKED");
                    }}
                  >
                    <IconRemove />{" "}
                    {changeLanguage(
                      activeLaguageStrings?.cart?.removeCartButton,
                      "Esvaziar carrinho",
                      activeLaguage
                    )}
                  </button>
                )}
              </div>
            )}

            {notItens ? (
              <div className="myne-carrinho-content__not-itens">
                <IconCart />
                <p>
                  {changeLanguage(
                    activeLaguageStrings?.cart?.notItensCart,
                    "O carrinho está vazio!",
                    activeLaguage
                  )}
                </p>
                <button
                  onClick={() =>
                    subdomain
                      ? history.push(`/`)
                      : history.push(`/${storeNAME}`)
                  }
                >
                  <IconLeft />
                  {changeLanguage(
                    activeLaguageStrings?.cart?.buttonBack,
                    "VOLTAR",
                    activeLaguage
                  )}
                </button>
              </div>
            ) : (
              ""
            )}

            {!notItens && width > 1340 ? (
              <div className="myne-carrinho-content__list">
                <div className="myne-carrinho-content__list-titles">
                  <span>
                    {changeLanguage(
                      activeLaguageStrings?.cart?.cartItens?.i1,
                      "Itens",
                      activeLaguage
                    )}
                  </span>
                  <span>
                    {changeLanguage(
                      activeLaguageStrings?.cart?.cartItens?.i2,
                      "Valor unit.",
                      activeLaguage
                    )}
                  </span>
                  <span>
                    {changeLanguage(
                      activeLaguageStrings?.cart?.cartItens?.i3,
                      "Quantidade",
                      activeLaguage
                    )}
                  </span>
                  <span>
                    {changeLanguage(
                      activeLaguageStrings?.cart?.cartItens?.i4,
                      "Valor total",
                      activeLaguage
                    )}
                  </span>
                </div>
                <section>
                  {carrinho?.map((product) => (
                    <ItemCart
                      carrinho={carrinho}
                      setCarrinho={setCarrinho}
                      product={product}
                      foo={foo}
                    />
                  ))}
                </section>
                <div
                  style={{ margin: 0 }}
                  className="myne-carrinho-content__list-titles"
                >
                  <p>
                    {changeLanguage(
                      activeLaguageStrings?.cart?.titleTotalPrice,
                      "Valor total:",
                      activeLaguage
                    )}{" "}
                    <b>
                      {total?.toLocaleString("pt-br", {
                        style: "currency",
                        currency: activeMoeda?.currencyId
                          ? activeMoeda?.currencyId
                          : "BRL",
                      })}
                    </b>
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}

            {!notItens && width <= 1340 ? (
              <div className="myne-carrinho-content__list-responsive">
                <section>
                  {carrinho?.map((product) => (
                    <ItemCartResponsive
                      carrinho={carrinho}
                      setCarrinho={setCarrinho}
                      product={product}
                      foo={foo}
                    />
                  ))}
                </section>
                <div className="myne-carrinho-content__list-titles-responsive">
                  <button onClick={() => setCarrinho([])}>
                    <IconRemove2 />{" "}
                    {changeLanguage(
                      activeLaguageStrings?.cart?.removeCartButtonResponsive,
                      "Esvaziar",
                      activeLaguage
                    )}
                  </button>

                  <p>
                    {changeLanguage(
                      activeLaguageStrings?.cart?.titleTotalPriceResponsive,
                      "Total:",
                      activeLaguage
                    )}{" "}
                    <b>
                      {total?.toLocaleString("pt-br", {
                        style: "currency",
                        currency: activeMoeda?.currencyId
                          ? activeMoeda?.currencyId
                          : "BRL",
                      })}
                    </b>
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}

            {!notItens && (
              <div className="myne-carrinho-content__bottom">
                <div className="myne-catalogo-content--filter__dica">
                  <IconDica />
                  <p>
                    {changeLanguage(
                      activeLaguageStrings?.dica,
                      "Dica: não realize pagamentos a desconhecidos. As ofertas e produtos apresentados nesse catálogo são de responsabilidade de",
                      activeLaguage
                    )}{" "}
                    <strong>{store_data?.ownerName}.</strong>
                  </p>
                </div>

                <button
                  onClick={() =>
                    subdomain
                      ? history.push(`/`)
                      : history.push(`/${storeNAME}`)
                  }
                >
                  <IconLeft />{" "}
                  {changeLanguage(
                    activeLaguageStrings?.cart?.buttonContinueBuy,
                    "CONTINUAR COMPRANDO",
                    activeLaguage
                  )}
                </button>
                <button
                  onClick={() =>
                    pedirWhatsapp(
                      store_data?.whatsApp,
                      formatTextMessage(
                        carrinho,
                        total,
                        activeMoeda,
                        activeLaguage
                      )
                    )
                  }
                >
                  {changeLanguage(
                    activeLaguageStrings?.cart?.buttonOrder,
                    "PEDIR POR WHATSAPP",
                    activeLaguage
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Carrinho;

const IconCart = () => (
  <svg
    width="82"
    height="80"
    viewBox="0 0 82 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.9375 64C20.625 64 17.0625 67.5625 17.0625 72.0625C17.0625 76.375 20.625 79.9375 24.9375 79.9375C29.4375 79.9375 33 76.375 33 72.0625C33 67.5625 29.4375 64 24.9375 64ZM0.9375 4C0.9375 6.25 2.8125 7.9375 5.0625 7.9375H9L23.4375 38.3125L18 48.0625C15 53.5 18.9375 60.0625 24.9375 60.0625H69C71.25 60.0625 72.9375 58.1875 72.9375 55.9375C72.9375 53.875 71.25 52 69 52H24.9375L29.4375 43.9375H59.25C62.25 43.9375 64.875 42.4375 66.1875 39.8125L80.4375 13.9375C81.9375 11.3125 80.0625 7.9375 77.0625 7.9375H17.8125L15.1875 2.3125C14.4375 0.8125 13.125 0.0625 11.625 0.0625H5.0625C2.8125 0.0625 0.9375 1.75 0.9375 4ZM65.0625 64C60.5625 64 57 67.5625 57 72.0625C57 76.375 60.5625 79.9375 65.0625 79.9375C69.375 79.9375 72.9375 76.375 72.9375 72.0625C72.9375 67.5625 69.375 64 65.0625 64Z"
      fill="#85D1F5"
    />
  </svg>
);

const IconLeft = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9844 7.01562H3.82812L8.70312 2.14062C9.07812 1.71875 9.07812 1.10938 8.70312 0.6875C8.32812 0.3125 7.67188 0.3125 7.29688 0.6875L0.6875 7.29688C0.3125 7.67188 0.3125 8.32812 0.6875 8.70312L7.29688 15.3125C7.67188 15.6875 8.32812 15.6875 8.70312 15.3125C9.07812 14.8906 9.07812 14.2812 8.70312 13.8594L3.82812 8.98438H14.9844C15.5469 8.98438 16.0156 8.5625 16.0156 8C16.0156 7.4375 15.5469 7.01562 14.9844 7.01562Z"
      fill="black"
      fill-opacity="0.87"
    />
  </svg>
);

const IconRemove = () => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 15.9844C1 17.1094 1.89062 18 3.01562 18H10.9844C12.1094 18 13 17.1094 13 15.9844V6C13 4.92188 12.1094 3.98438 10.9844 3.98438H3.01562C1.89062 3.98438 1 4.92188 1 6V15.9844ZM13 0.984375H10.5156L9.8125 0.28125C9.625 0.09375 9.34375 0 9.10938 0H4.89062C4.65625 0 4.375 0.09375 4.1875 0.28125L3.48438 0.984375H1C0.4375 0.984375 0.015625 1.45312 0.015625 2.01562C0.015625 2.53125 0.4375 3 1 3H13C13.5625 3 13.9844 2.53125 13.9844 2.01562C13.9844 1.45312 13.5625 0.984375 13 0.984375Z"
      fill="black"
      fill-opacity="0.87"
    />
  </svg>
);

const iconRight = ">";

const IconDica = () => (
  <svg
    width="10"
    height="14"
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 13C3 13.375 3.3125 13.6562 3.65625 13.6562H6.34375C6.6875 13.6562 7 13.375 7 13V12.3438H3V13ZM5 0.34375C2.4375 0.34375 0.34375 2.4375 0.34375 5C0.34375 6.59375 1.125 7.96875 2.34375 8.8125V10.3438C2.34375 10.6875 2.625 11 3 11H7C7.375 11 7.65625 10.6875 7.65625 10.3438V8.8125C8.875 7.96875 9.65625 6.59375 9.65625 5C9.65625 2.4375 7.5625 0.34375 5 0.34375ZM6.90625 7.71875L6.34375 8.125V9.65625H3.65625V8.125L3.09375 7.71875C2.1875 7.09375 1.65625 6.09375 1.65625 5C1.65625 3.15625 3.15625 1.65625 5 1.65625C6.84375 1.65625 8.34375 3.15625 8.34375 5C8.34375 6.09375 7.8125 7.09375 6.90625 7.71875Z"
      fill="#AA00FF"
    />
  </svg>
);

const IconRemove2 = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.703125 0.828125C0.328125 1.20312 0.328125 1.85938 0.703125 2.23438L4.40625 5.9375L6.60938 10.5781L5.25 13.0156C5.0625 13.3438 4.96875 13.7656 5.01562 14.1875C5.10938 15.2188 6.04688 16.0156 7.125 16.0156H14.4375L15.8438 17.375C15.3281 17.75 15 18.3125 15 19.0156C15 20.0938 15.8906 20.9844 17.0156 20.9844C17.6719 20.9844 18.2812 20.6562 18.6094 20.1406L20.7656 22.2969C21.1406 22.6719 21.75 22.6719 22.1719 22.2969C22.5469 21.9219 22.5469 21.2656 22.1719 20.8906L2.10938 0.828125C1.73438 0.453125 1.07812 0.453125 0.703125 0.828125ZM6.98438 14L8.10938 11.9844H10.4531L12.4688 14H6.98438ZM16.0312 11.9375C16.5938 11.7969 17.0625 11.4688 17.2969 10.9531L20.8594 4.48438C21.2344 3.82812 20.7656 2.98438 20.0156 2.98438H7.125L16.0312 11.9375ZM6.98438 17C5.90625 17 5.01562 17.8906 5.01562 19.0156C5.01562 20.0938 5.90625 20.9844 6.98438 20.9844C8.10938 20.9844 9 20.0938 9 19.0156C9 17.8906 8.10938 17 6.98438 17Z"
      fill="black"
      fill-opacity="0.87"
    />
  </svg>
);
