import React, { useEffect, useState } from "react";
import imgproductnull from "../../assets/images/NULL/nullproducts.svg";
import { onUpdateCART, removeItemCART } from "../../providers/catalogo";
import imgnull from "../../assets/images/NULL/null.webp";

function ItemCartResponsive({ product, setCarrinho, carrinho, foo }) {
  const amount = product?.amount;
  const price = product?.salePrice?.toFixed(2)?.replaceAll(".", ",");
  const total = (amount * product?.salePrice)?.toFixed(2)?.replaceAll(".", ",");

  const [amountproduct, setamountproduct] = useState(1);

  useEffect(() => {
    setamountproduct(amount);
  }, []);

  return (
    <div
      style={{ border: foo === product?.id ? "1px solid #aa00ff" : "" }}
      className="myne-catalogo-product__cart-responsive myne-modal--animation-top"
      id={`id=${product?.id}=myneid`}
    >
      {/* ITEM TOP */}
      <div>
        {/* IMAGE AND NAME */}
        <div className="myne-catalogo-product__cart-responsive-image">
          {product?.image ? (
            <img
              decoding="async"
              onLoad={(e) => {
                e.target.src = product?.image;
              }}
              src={imgnull}
              alt={product?.name + "-image"}
            />
          ) : (
            <IconImage />
          )}
          <p>{product?.name}</p>
        </div>
        {/* ADD AND REMOVE AMOUNT */}
        <section className="myne-catalogo-product__cart-responsive-image-add-remove">
          <IconRemove
            onClick={() => {
              if (amountproduct > 1) {
                setamountproduct((remove) => remove - 1);
                onUpdateCART(
                  { ...product, amount: amountproduct - 1 },
                  carrinho,
                  product,
                  setCarrinho
                );
              } else {
                setamountproduct(1);
                onUpdateCART(
                  { ...product, amount: 1 },
                  carrinho,
                  product,
                  setCarrinho
                );
              }
            }}
          />
          <input
            onChange={(e) => {
              let v = parseFloat(e.target.value);
              if (isNaN(amountproduct)) {
                setamountproduct(1);
                onUpdateCART(
                  { ...product, amount: 1 },
                  carrinho,
                  product,
                  setCarrinho
                );
              } else {
                setamountproduct(v);
                onUpdateCART(
                  { ...product, amount: v },
                  carrinho,
                  product,
                  setCarrinho
                );
              }
            }}
            value={amountproduct}
          />
          <IconPlus
            onClick={() => {
              setamountproduct((remove) => remove + 1);
              onUpdateCART(
                { ...product, amount: amountproduct + 1 },
                carrinho,
                product,
                setCarrinho
              );
            }}
          />
        </section>
      </div>
      {/* ITEM BOTTOM */}
      <section>
        {/* AMOUNT AND TOTAL PRICE */}
        <p>
          {amount} x {price} = <b>{total}</b>
        </p>
        {/* DELETE IN THE CART */}
        <IconDelete
          onClick={() => removeItemCART(product?.id, carrinho, setCarrinho)}
        />
      </section>
    </div>
  );
}

export default ItemCartResponsive;

const IconImage = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 21.3125V2.6875C24 1.1875 22.8125 0 21.3125 0H2.6875C1.1875 0 0 1.1875 0 2.6875V21.3125C0 22.8125 1.1875 24 2.6875 24H21.3125C22.8125 24 24 22.8125 24 21.3125ZM7.875 14.625L10.6875 18L14.8125 12.6875C15.0625 12.375 15.625 12.375 15.875 12.6875L20.5625 18.9375C20.875 19.375 20.5625 20 20 20H4C3.4375 20 3.1875 19.375 3.5 18.9375L6.8125 14.6875C7.0625 14.3125 7.5625 14.3125 7.875 14.625Z"
      fill="black"
      fill-opacity="0.36"
    />
  </svg>
);

const IconDelete = ({ ...rest }) => (
  <svg
    {...rest}
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 15.9844C1 17.1094 1.89062 18 3.01562 18H10.9844C12.1094 18 13 17.1094 13 15.9844V6C13 4.92188 12.1094 3.98438 10.9844 3.98438H3.01562C1.89062 3.98438 1 4.92188 1 6V15.9844ZM13 0.984375H10.5156L9.8125 0.28125C9.625 0.09375 9.34375 0 9.10938 0H4.89062C4.65625 0 4.375 0.09375 4.1875 0.28125L3.48438 0.984375H1C0.4375 0.984375 0.015625 1.45312 0.015625 2.01562C0.015625 2.53125 0.4375 3 1 3H13C13.5625 3 13.9844 2.53125 13.9844 2.01562C13.9844 1.45312 13.5625 0.984375 13 0.984375Z"
      fill="black"
      fill-opacity="0.87"
    />
  </svg>
);

const IconRemove = ({ ...rest }) => (
  <svg
    {...rest}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0.511719C3.85156 0.511719 0.511719 3.85156 0.511719 8C0.511719 12.1484 3.85156 15.4883 8 15.4883C12.1484 15.4883 15.4883 12.1484 15.4883 8C15.4883 3.85156 12.1484 0.511719 8 0.511719ZM10.9883 8.73828H5.01172C4.58984 8.73828 4.23828 8.42188 4.23828 8C4.23828 7.57812 4.58984 7.26172 5.01172 7.26172H10.9883C11.4102 7.26172 11.7617 7.57812 11.7617 8C11.7617 8.42188 11.4102 8.73828 10.9883 8.73828Z"
      fill="#FE2543"
    />
  </svg>
);

const IconPlus = ({ ...rest }) => (
  <svg
    {...rest}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.015625C4.46875 0.015625 0.015625 4.46875 0.015625 10C0.015625 15.5312 4.46875 19.9844 10 19.9844C15.5312 19.9844 19.9844 15.5312 19.9844 10C19.9844 4.46875 15.5312 0.015625 10 0.015625ZM13.9844 10.9844H10.9844V13.9844C10.9844 14.5469 10.5625 15.0156 10 15.0156C9.4375 15.0156 9.01562 14.5469 9.01562 13.9844V10.9844H6.01562C5.45312 10.9844 4.98438 10.5625 4.98438 10C4.98438 9.4375 5.45312 9.01562 6.01562 9.01562H9.01562V6.01562C9.01562 5.45312 9.4375 4.98438 10 4.98438C10.5625 4.98438 10.9844 5.45312 10.9844 6.01562V9.01562H13.9844C14.5469 9.01562 15.0156 9.4375 15.0156 10C15.0156 10.5625 14.5469 10.9844 13.9844 10.9844Z"
      fill="#58CC02"
    />
  </svg>
);
