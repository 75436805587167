import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Catalago from "./views/Catalago/Catalago";
import Carrinho from "./views/Catalago/Carrinho";
import "./styles/App.css";
import "./styles/modals.css";
import "./styles/Login/Login.css";
import "./styles/Cadastros/Cadastros.css";
import logo_myne from "./assets/images/LOGOS/logo 3.svg";
import { Analytics } from "@vercel/analytics/react";

const subdomain = /:\/\/([^/]+)/.exec(window.location.href)[1].split(".")[0];

function App() {
  const [carrinho, setCarrinho] = useState([]);
  // const [subdomain, setSubDomain] = useState(null);

  // useEffect(() => {
  //   const host = window.location.host;

  //   const arr = host.split(".").slice(0, host.includes("localhost") ? -1 : -2);
  //   if (arr.length > 0) setSubDomain(arr[0]);
  // }, []);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setLoading(false);
      }, [2200]);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [loading]);

  useEffect(() => {
    if (!loading && !subdomain) {
      window.location.href = "https://myneapp.com.br/";
    }
  }, [loading]);

  if (subdomain)
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <Catalago
              subdomain={subdomain}
              carrinho={carrinho}
              setCarrinho={setCarrinho}
            />
          </Route>
          <Route path="/carrinho">
            <Carrinho
              subdomain={subdomain}
              carrinho={carrinho}
              setCarrinho={setCarrinho}
            />
          </Route>
          <Analytics />
        </Switch>
      </BrowserRouter>
    );
  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img width={160} src={logo_myne} alt="Myne" />
    </div>
  );
}

export default App;
